

























































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/ClaimController'
import Button from '@/app/ui/components/Button/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTable from '@/app/ui/components/DataTable/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import Loading from '@/app/ui/components/Loading/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import dayjs from 'dayjs'
import { CLAIM_PAGINATION } from '@/app/infrastructures/misc/Constants'
import { Dictionary } from 'vue-router/types/router'

interface ParametersTypes {
  sortBy: string | number;
  filterBy: string | number;
  claimType: string;
  page: number;
  perPage: number;
  keyword: string;
}

@Component({
  components: {
    Button,
    TextInput,
    DropdownSelect,
    DataTable,
    PaginationNav,
    Loading,
  },
})
export default class ClaimDamagedGoodsPage extends Vue {
  controller = controller
  filterOptions = [
    { label: 'Show All', value: 'SHOWALL' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'On Progress', value: 'ONPROGRESS' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
  ]
  sortOptions = [
    { label: 'Newest', value: 'NEWEST' },
    { label: 'Oldest', value: 'OLDEST' },
  ]
  parameters = {
    page: 1,
    perPage: CLAIM_PAGINATION,
    keyword: '',
    sortBy: this.sortOptions[0],
    filterBy: this.filterOptions[0],
  }
  headers = [
    'Claim ID',
    'Claim External ID',
    'STT ID',
    'Shipment ID',
    'Phone Number',
    'Claim Type',
    'From',
    'Request Date',
    'Status',
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: Utils.alwaysNumber(queries.perPage) || CLAIM_PAGINATION,
        keyword: queries.keyword || '',
        sortBy:
          this.sortOptions.find((item) => item.value === queries.sortBy) ||
          this.sortOptions[0],
        filterBy:
          this.filterOptions.find((item) => item.value === queries.filterBy) ||
          this.filterOptions[0],
      }
    }

    this.fetchClaimList(true)
  }

  get params(): ParametersTypes {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      filterBy: this.parameters.filterBy.value,
      claimType: 'DAMAGED,LOST',
    }
  }

  get claimDataTable(): Array<string | number | undefined>[] {
    return controller.claimData.map((claim) => [
      claim.id,
      claim.externalClaimId,
      claim.packageId,
      claim.shipmentId,
      claim.customerPhone,
      claim.type,
      claim.from,
      this.formatDate(claim.createdAt as string),
      claim.status
    ])
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router.replace({
      query: { ...val },
    })
  }

  @Watch('parameters.keyword')
  onKeywordChanged(val: string): void {
    val === '' && this.fetchClaimList(true)
  }

  private fetchClaimList(resetPage = false) {
    if (resetPage) {
      this.parameters.page = 1
    }
    this.controller.getClaimList(this.params)
  }

  private formatDate(date: string) {
    return dayjs(date).format('DD MMM YYYY HH:mm [WIB]')
  }
}
